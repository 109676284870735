import { Container } from '@mui/material'
import RegisterContainer from '~/src/components/auth/register/RegisterContainer'
import useGetRegisterTemplate from '~/src/pages/auth/register/styles/useGetRegisterTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const Register = () => {
  const pageName: keyof typeof RoutesEnum = 'REGISTER'
  const styles = useGetRegisterTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>
      <Container sx={styles.getPageSx()}>
        <ContentContainer>
          <RegisterContainer/>
        </ContentContainer>
      </Container>
    </SlideFromRight>
  )
}

export default Register
