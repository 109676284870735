import { CreateCustomerResponse, Customer, HttpError } from '@eo-storefronts/eo-core'
import { useState } from 'react'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue } from '~/src/hooks/useEoState'
import routes from '~/src/router/enums/routes.enum'
import { create, createV2 } from '~/src/services/CustomerService'
import { IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import useSetAuthState from '~/src/hooks/auth/useSetAuthState'

interface ReturnsType {
  register(entity: Customer): Promise<boolean>,
  error: Record<string, string[]> | string | null,
  loading: boolean,
}

const useRegisterAsCustomer = (): ReturnsType => {
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const [ error, setError ] = useState<string | Record<string, string[]> | null>(null)
  const [ loading, setLoading ] = useState<boolean>(false)
  const { setAuthState } = useSetAuthState()
  const brand = useEoValue(BRAND_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const isUsingNewBrandLoginSystem = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)

  const register = async (entity: Customer): Promise<boolean> => {
    setError(null)
    setLoading(true)

    if (!brand?.id && !firm?.id) {
      return false
    }

    try {
      let response: CreateCustomerResponse | null = null

      if (isUsingNewBrandLoginSystem && brand?.id) {
        response = await createV2(entity, brand.id, false)
      } else if (firm?.id) {
        response = await create(entity, firm.id, false)
      }

      if (!response) {
        return false
      }

      setAuthState({
        customer: {
          id: response.id,
          uuid: response.uuid,
          token: ''
        },
        loggedInAs: undefined
      })

      push(resolve(routes.ACCOUNT_ACTIVATION))
      setLoading(false)

      return true

    } catch (e) {
      const errors = (e as HttpError).errors

      if (errors) {
        setError(errors)
      }
    }

    setLoading(false)
    return false
  }

  return { register, error, loading }
}

export default useRegisterAsCustomer
