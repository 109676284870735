import { SxProps } from '@mui/material'
import StylesRegisterServiceInterface from '~/src/pages/auth/register/styles/StylesRegisterServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesRegisterServiceStyleOne extends TemplateServiceStyleBase implements StylesRegisterServiceInterface {
  public getPageSx(): SxProps {
    return {
      my: 4,
      mt: `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(4)})`,
      width: '40%',
      [this.muiTheme.breakpoints.only('xs')]: {
        width: '90%'
      },
      [this.muiTheme.breakpoints.only('sm')]: {
        width: '80%'
      },
      [this.muiTheme.breakpoints.only('md')]: {
        width: '60%'
      }
    }
  }
}
