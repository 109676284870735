import { Box } from '@mui/material'
import { FormikProps } from 'formik'
import SocialLogins from '~/src/components/auth/social-logins/SocialLogins'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import Link from '~/src/components/mui-wrappers/Link'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { RegisterFormInterface } from '~/src/types/forms/RegisterFormInterface'

interface Props {
  loading: boolean,
  form: FormikProps<RegisterFormInterface>,
}

const RegisterFormActions = ({ loading, form }: Props) => {
  const { t } = useTranslations()
  const theme = useCustomMuiTheme()

  return (
    <Box sx={{
      display: 'grid',
      gridTemplateAreas: '"links button" "social-logins social-logins"',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'max-content',
      gap: 4,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'repeat(3, max-content)',
        gridTemplateAreas: '"button"' +
          '"social-logins"' +
          '"links"',
        gap: 1
      }
    }}
    >
      <Box sx={{
        gridArea: 'links',
        display: 'flex',
        justifyContent: 'center',
        gap: 1,
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'column',
          gap: 0
        }
      }}>
        <span>{t('auth.already_have_an_account')}</span>
        <Link
          href={RoutesEnum.LOGIN}
          sx={{
            fontSize: '1em',
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          {t('auth.login_my_account')}
        </Link>
      </Box>

      <Button
        loading={loading}
        disabled={!form.dirty || !form.isValid}
        variant='contained'
        type='submit'
        sx={{
          gridArea: 'button',
          width: '100%',
          fontWeight: 'bold',
          fontSize: '1em'
        }}
      >
        {t('auth.create_account')}
      </Button>

      <Box gridArea='social-logins'>
        <SocialLogins />
      </Box>
    </Box>
  )
}

export default RegisterFormActions
