import { Box, Checkbox, FormControlLabel, FormLabel } from '@mui/material'
import { FormikProps } from 'formik'
import FormikTextField from '~/src/components/mui-wrappers/fields/FormikTextField'
import Toggle from '~/src/components/mui-wrappers/styled/toggle'
import { useTranslations } from '~/src/hooks/useTranslations'
import { RegisterFormInterface } from '~/src/types/forms/RegisterFormInterface'
import PhoneInput from '~/src/components/mui-wrappers/styled/phone-input'

interface Props {
  form: FormikProps<RegisterFormInterface>,
}

const RegisterFormFields = ({ form }: Props) => {
  const { t } = useTranslations()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <FormikTextField
        TextFieldProps={{
          form,
          name: 'email',
          inputMode: 'email',
          label: t('auth.whats_your_email'),
          helperText: t('global.required')
        }}
      />

      <FormikTextField
        TextFieldProps={{
          form,
          name: 'password',
          type: 'password',
          label: t('auth.create_a_password'),
          helperText: t('global.required')
        }}
      />

      <FormikTextField
        TextFieldProps={{
          form,
          name: 'firstName',
          label: t('auth.first_name'),
          helperText: t('global.required')
        }}
      />

      <FormikTextField
        TextFieldProps={{
          form,
          name: 'lastName',
          label: t('auth.last_name'),
          helperText: t('global.required')
        }}
      />

      <PhoneInput
        PhoneInputProps={{
          form,
          name: 'phone',
          label: t('auth.phone'),
          helperText: t('global.required')
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2
        }}
      >
        <FormLabel
          id='account'
          sx={{
            color: 'inherit'
          }}
        >
          {t('auth.business_account')}
        </FormLabel>
        <Toggle
          name='isCompany'
          checked={form.values.isCompany}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
      </Box>

      {form.values.isCompany && (
        <>
          <FormikTextField
            TextFieldProps={{
              form,
              name: 'companyName',
              label: t('auth.company'),
              helperText: t('global.required')
            }}
          />

          <FormikTextField
            TextFieldProps={{
              form,
              name: 'companyVat',
              label: t('auth.vat'),
              helperText: t('global.required')
            }}
          />
        </>
      )}

      <FormControlLabel
        control={<Checkbox sx={{ color: 'inherit' }} />}
        label={t('auth.i_accept_to_receive_emails_and_promotions')}
      />
    </Box>
  )
}

export default RegisterFormFields
