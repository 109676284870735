import getRegisterTemplateService from '~/src/pages/auth/register/styles/GetRegisterTemplateService'
import StylesRegisterServiceInterface from '~/src/pages/auth/register/styles/StylesRegisterServiceInterface'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

const useGetRegisterTemplate = (): StylesRegisterServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('register')
  const layout = useEoValue(LAYOUT_STATE)

  useLayout('register')

  return getRegisterTemplateService(pageStyle, muiTheme, layout)
}

export default useGetRegisterTemplate
