import { Customer } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import { FormikProps, useFormik } from 'formik'
import * as yup from 'yup'
import RegisterFormActions from '~/src/components/auth/register/RegisterFormActions'
import RegisterFormFields from '~/src/components/auth/register/RegisterFormFields'
import FormError from '~/src/components/error/FormError'
import Link from '~/src/components/mui-wrappers/Link'
import useRegisterAsCustomer from '~/src/hooks/auth/useRegisterAsCustomer'
import { useTranslations } from '~/src/hooks/useTranslations'
import routes from '~/src/router/enums/routes.enum'
import { RegisterFormInterface } from '~/src/types/forms/RegisterFormInterface'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import ValidatorUtils from '~/src/utils/ValidatorUtils'

const RegisterForm = () => {
  const { t } = useTranslations()
  const { register, error, loading } = useRegisterAsCustomer()
  const locale = useEoValue(LANGUAGE_STATE)

  const formik: FormikProps<RegisterFormInterface> = useFormik<RegisterFormInterface>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      phone: '',
      isCompany: false,
      companyName: '',
      companyVat: ''
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t('errors.email_invalid'))
        .required(t('errors.email_required')),
      firstName: yup
        .string()
        .required(t('errors.firstName_required')),
      lastName: yup
        .string()
        .required(t('errors.lastName_required')),
      password: yup
        .string()
        .required(t('errors.password_required')),
      phone: yup
        .string()
        .test('isNumberValid', t('errors.phone_wrong_format'), ValidatorUtils.isPhoneNumberValid)
        .required(t('errors.phone_required')),
      isCompany: yup
        .boolean()
        .required(),
      companyName: yup
        .string().when('isCompany', {
          is: true,
          then: yup
            .string()
            .required(t('errors.company_required')),
          otherwise: yup.string()
        }),
      companyVat: yup
        .string().when('isCompany', {
          is: true,
          then: yup
            .string()
            .required(t('errors.vat_required')),
          otherwise: yup.string()
        })
    }),
    onSubmit: values => {
      register({
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        password: values.password,
        phone: values.phone,
        company: values.companyName,
        vat_number: values.companyVat,
        locale: locale
      } as Customer)
        .then((success: boolean) => {
          if (!success) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }
        })
    }
  })

  return (
    <>
      {error && <FormError errors={error}/>}

      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4
          }}
        >
          <RegisterFormFields form={formik} />

          <Typography>
            {t('auth.by_continuing_you_agree_to')}
            <Link
              href={routes.PRIVACY_POLICY}
              sx={{
                mx: 0.5
              }}
            >
              {t('auth.privacy_policy')}
            </Link>
            {t('auth.and_our')}
            <Link
              href={routes.TERMS_AND_CONDITIONS}
              sx={{
                mx: 0.5
              }}
            >
              {t('auth.terms_and_conditions')}
            </Link>
          </Typography>

          <RegisterFormActions
            loading={loading}
            form={formik}
          />
        </Box>
      </form>
    </>
  )
}

export default RegisterForm
